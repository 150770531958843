
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import jwt_decode from "jwt-decode";
import { ReplaySubject, of, catchError, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { client_id, environment } from 'src/environments/environment';
import { oAuthPayload } from '../config/oAuth.config';
import { UserProfile } from '../model/user-profile.model';
import { StorageService } from '../service/storage.service';
import { AuthService } from '../state/auth/auth.service';
import { CacheService } from './../state/cache/cache.service';
import { loadUserSuccess } from './../store/user.action';
import { AuthQuery } from '../state';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  public userProfile: ReplaySubject<UserProfile> = new ReplaySubject();

  constructor(
    private httpClient: HttpClient, 
    private storageService: StorageService, 
    private store: Store<any>,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private cacheService: CacheService,
    private router: Router) { }

    handleLogin(code: string | undefined | null): Observable<any> {
      // Return an observable directly, even if code is undefined or null
      if (!code) {
        return of(null);
      }
  
      const payload = oAuthPayload(code);
  
      // Define the request headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });

      console.log('environment.oAuthTokenURL', environment.oAuthTokenURL);
  
      // Perform the HTTP POST request
      return this.httpClient.post(environment.oAuthTokenURL, payload, { headers }).pipe(
        // Use the tap operator to handle the response and set the token
        tap((response: any) => {
          // Process the response and set the token (implement setToken function)
          this.setToken(response);
        }),
        // Use catchError to handle any errors that occur during the request
        catchError((error: HttpErrorResponse) => {
          console.error('Error getting OAuth token:', error);
          // Return an observable of null in case of error
          return of(null);
        })
      );
    }
  DecodeToken(token: string): UserProfile {
    return jwt_decode<UserProfile>(token);
  }
  setToken(jwtToken: any) {
    this.authService.setAuth(jwtToken);
    const userProfile = this.DecodeToken(jwtToken.id_token);
    //for keeping same structure as store
    const user = {
      profile: userProfile
    }
    // this.storageService.store('api_token', jwtToken.id_token);
    this.storageService.store('recipients', userProfile.email);
    this.storageService.store('profile', userProfile);
    this.storageService.store('user', user);

    this.storageService.store('sessionID', userProfile.sid);
    this.userProfile.next(userProfile)
    this.store.dispatch(loadUserSuccess({profile:userProfile}))
  }

  logout() {
    setTimeout(() => {
      this.cacheService.resetAll();
      this.storageService.clear();

      this.router.navigateByUrl('/login');
    }, 0);
  }
}
