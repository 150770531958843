
import { Component, OnInit} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, from, map, of, switchMap, take } from 'rxjs';
import { AccountQuery } from 'src/app/modules/account/state';
import { AuthService } from 'src/app/state';
import { AuthExpiredComponent } from './auth/auth-expired/auth-expired.component';
import { OAuthService } from './auth/oAuth.service';
import { AuthStore } from './state';
import { AuthQuery } from './state/auth/auth.query';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public logo = 'https://assets.agridence.com/logos/gpsnr/gpsnr.png';
  public profile$ = this.authQuery.userAuth$;

  constructor(
    private afAuth: AngularFireAuth,
    private authStore: AuthStore,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private accountQuery: AccountQuery,
    private dialogService: DialogService,
    private oAuthService: OAuthService
  ) { 
  }
  ngOnInit(): void { 
    this.authService.tokenExpired$
      .pipe(untilDestroyed(this))
      .subscribe((isExpired) => {
        if (isExpired) {
          // Show the modal when the token is expired
          this.showExpiredModal();
        }
      });

      // Subscribe to the tokenExpired$ observable
    this.authService.customTokenExpired$
      .pipe(
        untilDestroyed(this),
        switchMap(isExpired => {
          if(isExpired && !this.authService.isTokenExpired()) {
            const apiToken = this.authQuery.getIdToken();
            return this.signInWithCustomToken();
          }
          return of(isExpired);
          } 
        ))
      .subscribe();
  }

  showExpiredModal(): void {
    const ref = this.dialogService.open(AuthExpiredComponent, {
      width: '300px',
      position: 'center',
      closable: true,
      header: 'Session Expired'
    });

    ref.onClose.
      pipe( untilDestroyed(this))
      .subscribe((user: any) => {
        this.oAuthService.logout();
    });
  }

  signInWithCustomToken() {
    const token = this.authStore.getValue()?.upload?.token;
    if (token) {
      const profile = this.accountQuery.getValue()?.profile;

      if (profile) {
        return this.authService.geUserUploadTokenApi({ orgId: profile.orgId }).pipe(
          switchMap(response => {
            if (response?.token) {
              this.authStore.update(state => ({
                upload: { ...state.upload, ...response }
              }))
            }
            // Convert the promise to an observable using `from`
            return from(this.afAuth.signInWithCustomToken(response.token)).pipe(
              // Handle the result of the sign-in operation
              map(() => {
                // Return `true` if the sign-in is successful
                return of(response);
              }),
              catchError(error => {
                console.error('Error while signing in with custom token:', error);
                
                this.oAuthService.logout();
                // Handle error here, e.g., returning a false observable
                return of(response);
              })
            );
          }),
          map(response => !!response?.token),
          catchError(error => {
            console.error('Error while fetching user upload token:', error);
            return of(false);
          }),
          take(1),
        );
      }
    }

    return of(null);
  }
}

