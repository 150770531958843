import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DashboardService {
    private _sidebarVisibility = new BehaviorSubject<boolean>(false);
    public sidebarVisibility$ = this._sidebarVisibility.asObservable();

    private _refreshForms = new BehaviorSubject<boolean>(false);
    public refreshForms$ = this._refreshForms.asObservable();

    constructor() { }

    toggleSidebar() {
        this._sidebarVisibility.next(!this._sidebarVisibility.value);
    }

    refreshForms(bool: boolean) {
        this._refreshForms.next(bool);
    }
}