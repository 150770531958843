import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthManagerService } from '../auth/auth-manager';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  currentRoute = ''
  constructor(private activateRoute: ActivatedRoute,
    private route: Router,
    private authManager: AuthManagerService,
  ) { }

  ngOnInit(): void {
    this.currentRoute = this.activateRoute.snapshot.url.join('/');
    if(this.currentRoute === 'signup'){
      this.signupWithRedirect();
    } else {
      this.loginWithRedirect();
    }
  }

  async loginWithOAuth() {
    if (this.isCodeFlow) {
      await this.authManager.loginWithAgridence(this.code);
      this.redirectToForm();
    }
    else {
      if(this.currentRoute === 'signup'){
        this.signupWithRedirect();
      } else {
        this.loginWithRedirect();
      }
    }
  }
  

  get isCodeFlow(): boolean {
    return this.code !== null;
  }

  get code(): string {
    return this.activateRoute.snapshot.queryParamMap.get('code')!;
  }

  loginWithRedirect(): void {
    window.location.href = environment.oAuthLoginURL;
  }

  signupWithRedirect(): void {
    window.location.href = environment.oAuthSignupURL;
    return;
  }

  redirectToForm() {
    this.route.navigateByUrl('/');
  }
}
