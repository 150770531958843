import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { OAuthService } from './oAuth.service';

export abstract class LoginManager {}
@Injectable({
  providedIn: 'root',
})
export class AuthManagerService implements LoginManager {
  constructor(
    private oAuthService: OAuthService,
  ) {}

  loginWithAgridence(code: string | undefined | null): Observable<any> {
    if (!code) {
        // If code is null or undefined, return an observable of null or an empty result
        return of(null);
    }
    
    // Call handleLogin and process the observable response
    return this.oAuthService.handleLogin(code).pipe(
        // Perform additional processing if necessary (e.g., logging, saving data)
        tap(response => {
            // Handle the response (e.g., log it or save data)
            console.log('Login with Agridence response:', response);
            // Additional processing can go here
        }),
        // Use catchError to handle any errors that occur during the request
        catchError((error: any) => {
            // Log the error for debugging purposes
            if (error instanceof HttpErrorResponse) {
                console.error('HTTP error during login with Agridence:', error);
            } else {
                console.error('Unexpected error during login with Agridence:', error);
            }

            this.oAuthService.logout();

            // You can return a specific observable or use of() to return an empty observable
            return of(null);
        })
    );
}

}
