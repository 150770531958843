import { ID } from '@datorama/akita';

export interface Auth {
  token: any;
  id: string;
}

export function createAuth(params: Partial<Auth>) {
  return {

  } as Auth;
}
