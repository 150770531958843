import { UserProfile } from './../model/user-profile.model';


/* NgRx */
import { createAction, props } from '@ngrx/store';

export const loadUserSuccess = createAction(
  '[Auth API] Load Success',
  props<{profile:UserProfile }>()
);
