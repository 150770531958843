import { AccountStore } from './account.store';
import { Injectable } from '@angular/core';
import { Account, Organization, UserOrganizations } from './account.model';

import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountQuery } from './account.query';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private accountStore: AccountStore,
    private http: HttpClient,
    private accountQuery: AccountQuery
  ) {
  }

  getUserProfileApi() {
    return this.http.get<any>(`${environment.RR_API}/members/user/me`);
  }

  addUser(profile: Account) {
    this.accountStore.update(state => ({
      profile: { ...state.profile, ...profile }
    }));
  }

  isRoleAdmin() {
    return this.accountStore.getValue().profile?.role === 'rr/admin';
  }

  isGPSNRMember() {
    return this.accountStore.getValue().profile?.isGpsnrMember;
  }

  getTeamMembers(pageNumber: number, pageSize: number, name: string): Observable<any> {
    const filter = name === '' ? `&page=${pageNumber || 1}&size=${pageSize || 5}` : `&page=${pageNumber || 1}&size=${pageSize || 5}&name=${name}`
    return this.http.get<any>(`${environment.RR_API}/members/${this.accountStore.getValue().profile?.orgId}/teams?excludeSelf=true${filter}`);
  }

  addTeamMember(payload: any): Observable<Account> {
    return this.http.post<Account>(`${environment.RR_API}/members/${this.accountStore.getValue().profile?.orgId}/team`, payload);
  }

  getOrgIndex(orgId: string): number {
    let i = 0;
    this.accountQuery.profile$.subscribe(profile => {
      if (profile) {
        i = profile.organizations.findIndex((org: Organization) => org.orgId === orgId);
      }
    });
    return i;
  }

  updateUser(updatedInfo: any): Observable<any> {
    return this.http.patch<any>(`${environment.RR_API}/members/${this.accountStore.getValue().profile?.orgId}/team`, updatedInfo);
  }

  updateUserStore() {
    this.getUserProfileApi().subscribe(res => {
      const orgIndex = this.getOrgIndex(this.accountStore.getValue().profile.orgId);
      const user: Account = {
        email: res.email,
        orgId: this.accountStore.getValue().profile.orgId,
        organizations: [...res.organizations],
        ...res.organizations[orgIndex]
      };
      this.accountStore.update(state => ({
        profile: user
      }));
    })
  }

  updateCompany(updatedCompanyInfo: Partial<Organization>): Observable<Organization> {
    return this.http.patch<Organization>(`${environment.RR_API}/members/${this.accountStore.getValue().profile?.orgId}`, updatedCompanyInfo);
  }

  updateCompanyStore(res: any, orgId: string) {
    let i = this.getOrgIndex(orgId);

    this.accountStore.update(state => ({
      profile: {
        ...state.profile,
        organizations: state.profile.organizations.map((org: Organization, index: number) => {
          if (index === i) {
            return {
              ...org,
              address: res.address,
              contactEmail: res.contactEmail,
              companyName: res.name
            };
          }
          return org;
        })
      }
    }));
  }

  getMemberIndex(email: string) {
    let i = 0;
    this.accountQuery.members$.subscribe(members => {
      if (members) {
        i = members.findIndex((mem: Account) => mem.email === email);
      }
    });
    return i;
  }

  updateMemberStore(updatedInfo: Partial<Account>) {
    const i = this.getMemberIndex(updatedInfo.email || '');
    this.accountStore.update(state => ({
      members: state.members.map((mem: Account, index: number) => {
        if (index === i) {
          return {
            ...mem,
            role: updatedInfo.role,
          };
        }
        return mem;
      })
    }));
  }

  storeTeamMembers(members: Account) {
    this.accountStore.update(state => ({
      ...state.members, members
    }));
  }

  switchUserCompany(user: any) {
    this.accountStore.update(state => ({
      profile: { ...state.profile, ...user }
    }));
  }

  updateUserOrgId(userOrgId: string) {
    this.accountStore.update(state => ({
      profile: { ...state.profile, ...{ orgId: userOrgId } }
    }));
  }

  deleteUser(payload: any) {
    const options = {
      body: payload
    };
    return this.http.delete<any>(`${environment.RR_API}/members/${this.accountStore.getValue().profile?.orgId}/teams`, options);
  }

  isGPSNRSecretariat(): boolean {
    return this.accountStore.getValue().profile?.isGpsnrSecretariat;
  }
}
