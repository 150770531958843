import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { UtilityService } from '../utility/utility.service';
import { TOAST_MESSAGES } from '../constants/toast-messages.const';
import { OAuthService } from '../auth/oAuth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private utilityService: UtilityService,
    private oAuthService: OAuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const isUnauthorizedAccess = error.status === 401;
        const errorMessage = isUnauthorizedAccess
          ? TOAST_MESSAGES.UNAUTHORIZED_ACCESS
          : TOAST_MESSAGES.GENERIC_ERROR;

          if(!this.utilityService.hideErrorToast) {
            this.utilityService.showErrorToast(errorMessage);
          }
     
        if (isUnauthorizedAccess) {
          this.oAuthService.logout();
        }
        return throwError(() => error.message);
      })
    );
  }
}
