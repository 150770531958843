import { QueryEntity, StoreConfig } from '@datorama/akita';
import { AccountStore, AccountState } from './account.store';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { CAN_EDIT_ROLE } from 'src/app/constants/global.const';

@StoreConfig({
  name: 'account',
  idKey: 'id'
})
@Injectable({
  providedIn: 'root'
})
export class AccountQuery extends QueryEntity<AccountState> {
  profile$ = this.select(state => state.profile);
  userCanSubmit$ = this.select(state => state.profile && state.profile.role && state.profile.role !== CAN_EDIT_ROLE) ;
  members$ = this.select(state => state.members);

  constructor(protected store: AccountStore) {
    super(store);
  }

  getOrganizationInfo(orgId: string) {
    return this.profile$.pipe(
      map(profile => {
        const orgs = profile?.organizations;
        if (!orgs) {
          return undefined;
        }
        return orgs.find((org: any) => org.orgId === orgId);
      })
    );
  }

}