import { client_id, client_secret } from 'src/environments/environment';
import { GET_CURRENT_URL } from '../utility/utility';

export const oAuthPayload=(code:string)=>({
  'grant_type': 'authorization_code',
  'client_id': client_id,
  'client_secret': client_secret,
  'code': code,
  'redirect_uri': GET_CURRENT_URL(),
  'scope': 'openid email profile'
});
