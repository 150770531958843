export const GET_CURRENT_URL = (): string =>
  `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}`

export const replaceTokens = (name: string = '', value: string = '', template: String = '') => {
  return template.replace("{" + name + "}", value);
}

export const isEmpty = (obj: any) => !Object.keys(obj).length;

export const stripHtml = (htmlString: string) => {
    // Replace multiple spaces or newline characters with a single newline character
    const result = htmlString
        .replace( /<[^>]*>/g, '') // handle all html tag that as <>, </>
        .replace(/\n/g, ' ') // handle newline characters to space

        return result;
}
