import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-auth-expired',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule
  ],
  templateUrl: './auth-expired.component.html',
  styleUrl: './auth-expired.component.scss'
})
export class AuthExpiredComponent {

  constructor(public ref: DynamicDialogRef) { }

  onLogin() {
    this.ref.close();
  }
}
