import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Account, AccountService } from '@modules/account/state';
import { catchError, from, map, of, switchMap, take } from 'rxjs';
import { AuthManagerService } from 'src/app/auth/auth-manager';
import { OAuthService } from 'src/app/auth/oAuth.service';
import { AuthQuery, AuthService, AuthStore } from 'src/app/state';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private router: Router,
    private authManager: AuthManagerService,
    private accountService: AccountService,
    private authService: AuthService,
    private authStore: AuthStore,
    private authQuery: AuthQuery,
    private afAuth: AngularFireAuth,
    private location: Location,
    private oAuthService: OAuthService
  ) { }

  // Initialization function
  initializeApp(): Promise<void> {
    console.log('initialize');
    return new Promise(async (resolve, reject) => {

      const currentUrl = this.location.path(true);

      // Use URLSearchParams to parse the query parameters from the URL
      const queryParams = new URLSearchParams(currentUrl.split('?')[1]);
      const code = queryParams.get('code');
      if (code) {
        // Call the loginWithAgridence function and handle the observable result
        this.authManager.loginWithAgridence(code).pipe(
          take(1), // Use take(1) to complete the observable after the first emission
          // You can handle errors here if necessary using catchError or finalize
        ).subscribe(loginWithAgridenceResult => {
          if (loginWithAgridenceResult) {
            // If the loginWithAgridence result is successful, call handleToken
            this.handleToken(code).pipe(take(1)).subscribe(response => {
              // Navigate based on the response and resolve the promise
              this.router.navigateByUrl(response ? '/' : '/login');
              resolve();
            });
          } else {
            this.oAuthService.logout();
            // If loginWithAgridence did not return a successful result, resolve the promise
            resolve();
          }
        });
      } else {
        // If there is no code, check the API token and handle navigation accordingly
        const apiToken = this.authQuery.getIdToken() && !this.authService.isTokenExpired();

        if(currentUrl === "/signup"){
          this.router.navigateByUrl('/signup');
          resolve();
        }
        if (!apiToken && currentUrl !== "/signup") {
          this.oAuthService.logout();
        }

        // Resolve the promise since there is no code to handle
        resolve();
      }

    });
  }

  handleToken(code: string) {
    return this.accountService.getUserProfileApi().pipe(
      switchMap(newProfile => {
        if (!newProfile) return of(false);

        const primaryOrg = newProfile.organizations[0];
        const user: Account = {
          email: newProfile.email,
          organizations: [...newProfile.organizations],
          ...primaryOrg
        };

        return this.authService.geUserUploadTokenApi({ orgId: primaryOrg.orgId }).pipe(
          map(response => {
            if (response?.token) {
              // Update the auth store with the token and code
              this.authStore.update(state => ({
                upload: { ...state.upload, ...response },
                code
              }));

              // Add the user to the account service
              this.accountService.addUser(user);
            }
            return response;
          }),
          switchMap(response => {
            // Convert the promise to an observable using `from`
            return from(this.afAuth.signInWithCustomToken(response.token)).pipe(
              // Handle the result of the sign-in operation
              map(() => {
                return true;
              }),
              catchError(error => {
                console.error('Error while signing in with custom token:', error);
                // Handle error here, e.g., returning a false observable
                return of(false);
              })
            );
          }),
          catchError(error => {
            console.error('Error while fetching user upload token:', error);
            // Add user to the account service
            this.accountService.addUser(user);
            // Handle error here, e.g., returning a false observable
            return of(false);
          })
        );
      }),
    );
  }
}
