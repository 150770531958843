export interface Account {
  id?: string;
  name: string;
  email: string;
  role: string;
  firstName?: string;
  lastName?: string
  orgId?: string;
  status?: string;
  primary?: boolean;
  organizations?: Organization[];
}

export interface Organization {
  orgId: string;
  companyName: string;
  companyEmail: string;
  address: string;
  fullName: string;
  role: string;
  primary?: boolean;
  isGpsnrMember: boolean;
  isGpsnrSecretariat: boolean;
  status: string;
  category: string;
}

export interface UserOrganizations {
  email: string;
  organizations: Organization[];
}

export function createAccount(params?: Partial<Account>) {
  return {
  } as Account;
}
