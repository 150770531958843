import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, AccountService } from '@modules/account/state';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthManagerService } from '../auth/auth-manager';
import { AuthService, AuthStore } from '../state';
import { AuthQuery } from '../state/auth/auth.query';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authManager: AuthManagerService,
    private accountService: AccountService,
    private authService: AuthService,
    private authStore: AuthStore,
    private authQuery: AuthQuery,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    const apiToken = this.authQuery.getIdToken() && !this.authService.isTokenExpired();

    setTimeout(() => {
      if (apiToken) {
        this.router.navigateByUrl('/dashboard');
      } else {
        this.router.navigateByUrl('/login');
      }
    }, 0);
  }

  async loginWithOAuth(code: string) {
    try {
      await this.authManager.loginWithAgridence(code);
      this.handleOAuthLogin(code);
    } catch (error) {
      console.error('Login failed:', error);
      this.router.navigateByUrl('/login-error');
    }
  }

  handleOAuthLogin(code: string) {
    this.accountService.getUserProfileApi().pipe(
      switchMap(newProfile => {
        if (!newProfile) return of(false);

        const primaryOrg = newProfile.organizations[0];
        const user: Account = {
          name: primaryOrg.fullName,
          email: newProfile.email,
          role: primaryOrg.role,
          orgId: primaryOrg.orgId,
          organizations: [...newProfile.organizations]
        };

        return this.authService.geUserUploadTokenApi({ orgId: primaryOrg.orgId }).pipe(
          map(response => {
            if (response?.token) {
              // Update the auth store with the token and code
              this.authStore.update(state => ({
                upload: { ...state.upload, ...response },
                code
              }));

              // Add the user to the account service
              this.accountService.addUser(user);
            }
            return response;
          }),
          switchMap(response => {
            // Convert the promise to an observable using `from`
            return from(this.afAuth.signInWithCustomToken(response.token)).pipe(
              // Handle the result of the sign-in operation
              map(() => {
                // Return `true` if the sign-in is successful
                return true;
              }),
              catchError(error => {
                console.error('Error while signing in with custom token:', error);
                // Handle error here, e.g., returning a false observable
                return of(false);
              })
            );
          }),
          catchError(error => {
            console.error('Error while fetching user upload token:', error);
            // Add user to the account service
            this.accountService.addUser(user);
            // Handle error here, e.g., returning a false observable
            return of(false);
          })
        );
      }),
      take(1)
    ).subscribe(response => {
      this.router.navigateByUrl(response ? '/dashboard' : '/login');
    });
  }
}
