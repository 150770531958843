import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { BehaviorSubject, Observable, distinctUntilChanged, map, of, timer } from "rxjs";
import { environment } from "src/environments/environment";
import { UserProfile } from '../../model/user-profile.model';
import { AuthStore } from './auth.store';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public tokenExpired$ = timer(0, 60000).pipe( // Check every 60 seconds
    map(() => this.isTokenExpired()),
    distinctUntilChanged() // Emit only if the expiration status changes
  );

  public customTokenExpired$ = timer(0, 60000).pipe( // Check every 60 seconds
    map(() => this.isCustomTokenExpired()),
    distinctUntilChanged() // Emit only if the expiration status changes
  );
  
  constructor(
    private authStore: AuthStore,
    private http: HttpClient
  ) {
  }

  setAuth(token: string) {
    const id = new Date().getTime().toString();
    this.authStore.add({token, id});
    this.authStore.setActive(id);
  }

  decodeToken(token: string): UserProfile {
    return jwt_decode<UserProfile>(token);
  }

  
  isTokenExpired() {
    const authValue = this.authStore.getValue();

    if(!authValue?.ids?.length) {
      return null;
    }

    // Retrieve the first ID from the `ids` array using optional chaining
    const firstId = authValue?.ids?.[0];

    // If there is a first ID, retrieve the corresponding entity from the `entities` object
    const firstEntity = firstId ? authValue?.entities?.[firstId] : null;

    // Use optional chaining to safely access the `api_token` property from the first entity's `token` object
    const apiToken = firstEntity?.token?.id_token ?? null;

    const decodedToken = this.decodeToken(apiToken);

    const currentTimestamp = Math.floor(Date.now() / 1000);

    return currentTimestamp >= decodedToken.exp;
  }

  isCustomTokenExpired() {
    const authValue = this.authStore.getValue()?.upload?.token;

    if(!authValue) {
      return null;
    }

    const decodedToken = this.decodeToken(authValue);

    const currentTimestamp = Math.floor(Date.now() / 1000);
    
    const customTokenExpiration = decodedToken.exp - 300; // Subtract 300 seconds

    return currentTimestamp >= customTokenExpiration;
  }

  geUserUploadTokenApi(params: {orgId: string}) {
    return this.http.get<any>(`${environment.RR_API}/auth/${params.orgId}/submitter/token`);
  }

}

