import { UserState } from './user.selector';
import { UserProfile } from './../model/user-profile.model';
import { createReducer, on, createAction } from '@ngrx/store'
import { loadUserSuccess } from './user.action';
const initialState: Partial<UserState> = {};

export const userProfileReducer = createReducer(
  initialState,
  on(loadUserSuccess, (state, action) => {
    return {
      ...state,
      profile:action.profile
    }
  })
)
