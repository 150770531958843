import jwt_decode from 'jwt-decode';
import { QueryEntity } from '@datorama/akita';
import { AuthStore, AuthState, authStore } from './auth.store';
import { UserProfile } from '../../model/user-profile.model';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { Auth } from './auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthQuery extends QueryEntity<AuthState> {

  userAuth$: Observable<UserProfile | undefined> = (this.selectActive() as Observable<Auth>).pipe(
    map((auth: Auth) => {
      return auth && auth.token && auth.token?.id_token ? jwt_decode(auth.token?.id_token as string) : undefined
    })
  );


  constructor(protected store: AuthStore) {
    super(store);
  }

  getIdToken() {
    const authValue = this.getValue();

    // Retrieve the first ID from the `ids` array using optional chaining
    const firstId = authValue?.ids?.[0];

    // If there is a first ID, retrieve the corresponding entity from the `entities` object
    const firstEntity = firstId ? authValue?.entities?.[firstId] : null;

    // Use optional chaining to safely access the `api_token` property from the first entity's `token` object
    const apiToken = firstEntity?.token?.id_token ?? null;

    return apiToken;
  }

  getIdTokenDecode() {
    const authValue = this.getValue();
  
    // Retrieve the first ID from the `ids` array using optional chaining
    const firstId = authValue?.ids?.[0];
  
    // If there is a first ID, retrieve the corresponding entity from the `entities` object
    const firstEntity = firstId ? authValue?.entities?.[firstId] : null;
  
    // Use optional chaining to safely access the `api_token` property from the first entity's `token` object
    const apiToken = firstEntity?.token?.id_token ?? null;
  
    return jwt_decode(apiToken);
  }
}




let authQueryInstance: AuthQuery;

export function getAuthQueryInstance(): AuthQuery {
  if (!authQueryInstance) {
    authQueryInstance = new AuthQuery(authStore);
  }
  return authQueryInstance;
}

export const authQuery = getAuthQueryInstance();

