import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Auth } from './auth.model';
import { Injectable } from '@angular/core';

export interface AuthState extends EntityState<Auth> {}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({
  name: 'auth', 
  idKey: 'id'
})
export class AuthStore extends EntityStore<AuthState> {

  constructor() {
    super();
  }

}

export const authStore = new AuthStore();
