import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/state';
import { AuthQuery } from '../state/auth/auth.query';

import { OAuthService } from '../auth/oAuth.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private oAuthService: OAuthService,
    private authQuery: AuthQuery
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> | any {
    // Check if either the token or custom token is expired
    const isTokenExpired = this.authService.isTokenExpired();

    // If either token is expired, log out and return an observable of null
    if (isTokenExpired) {
      this.oAuthService.logout();
      console.warn('Token or custom token is expired. Logging out and blocking the request.');
      return; // Return an observable of null to block the request
    }

    // Get the ID token and check if it exists
    const token = this.authQuery.getIdToken();
    if (token) {
      // Construct the auth token with "Bearer" prefix
      const authToken = `Bearer ${token}`;

      // Clone the request and set the Authorization header
      const modifiedRequest = request.url.includes('oath') ? request : request.clone({
        setHeaders: {
          Authorization: authToken,
        },
      });

      // Handle the modified request
      return next.handle(modifiedRequest);
    } else {
      console.warn('No auth token found. Sending request without authorization header.');
      // Handle the request as is since there is no token
      return next.handle(request);
    }
  }
}
