import { EntityState, EntityStore, StoreConfig, persistState } from '@datorama/akita';
import { Account, createAccount } from './account.model';
import { Injectable } from '@angular/core';

export interface AccountState extends EntityState<Account> {}

@StoreConfig({
  name: 'account'
})
@Injectable({
  providedIn: 'root'
})
export class AccountStore extends EntityStore<AccountState> {
  
  profile = this.getValue()?.profile;
  
  constructor() {
    super(createAccount());
  }

}

export const accountStore = new AccountStore();

export const accountPersistStorage = persistState({
  include: ['account'],
  key: 'accountStore',
});
