import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Submission, createSubmission } from './submission.model';
import { Injectable } from '@angular/core';


export interface SubmissionState extends EntityState<Submission> {}

@StoreConfig({
  name: 'submissions',
  idKey: 'id'
})
@Injectable({
  providedIn: 'root'
})
export class SubmissionStore extends EntityStore<SubmissionState> {

  constructor() {
    super(createSubmission());
  }

}

export const submissionStore = new SubmissionStore();
