import { GET_CURRENT_URL } from './../app/utility/utility';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const client_id = 'VOgGdQasWvEYuQbF3khlnHxbTUFDPiXo';
export const client_secret = 'cnOG72E9ysHrx7Z8jWWFp7QsyAjL4jJ4w2LWiHx3kylQgWf_q64TrG8DxGT66dQE';

export const environment = {
  oAuthTokenURL: 'https://rrv2-dev.us.auth0.com/oauth/token',
  oAuthLoginURL: `https://rrv2-dev.us.auth0.com/authorize?client_id=${client_id}&response_type=code&prompt=login&scope=email%20openid%20profile%20offline_access&redirect_uri=${GET_CURRENT_URL()}&audience=https://forms.agridence.com`,
  oAuthSignupURL: `https://rrv2-dev.us.auth0.com/authorize?client_id=${client_id}&response_type=code&prompt=login&scope=email%20openid%20profile%20offline_access&redirect_uri=${GET_CURRENT_URL()}&audience=https://forms.agridence.com&screen_hint=signup`,
  logout_url:`https://rrv2-dev.us.auth0.com/oidc/logout?post_logout_redirect_uri=${GET_CURRENT_URL()}/logout`,
  storage_bucket:'formsdataroom/orgs/{ownerOrgId}/submissionCycle/{submissionCycleId}/forms/{formId}/submissions/{submissionId}/{fileName}',
  production: false,
  name:'dev',
  RR_API: 'https://api-rrforms-v2-development.agridence.com/api/v1',

  firebase: {
    apiKey: "AIzaSyCCefSqEqw5Vga5YlItyd9vpzmhSlqgZ-0",
    authDomain: "hcplatform-development.firebaseapp.com",
    projectId: "hcplatform-development",
    storageBucket: "hcplatform-development.appspot.com",
    messagingSenderId: "612736903809",
    appId: "1:612736903809:web:5df1685f56b6259d4afb80",
    measurementId: "G-B71NCTRB5V"
  },
  domain: 'http://localhost:3000'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
