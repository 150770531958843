import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthGuard } from './auth/auth-guard';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: 'token', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: LoginComponent },
  { path: '', component: AuthCallbackComponent },

  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule),
    canActivate: [AuthGuard,]
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard,]
  }

  // { path: 'submission-dashboard', component: SubmissionDashboardComponent, canActivate: [AuthGuard] },
  // { path: 'supplier-submission', component: SupplierSubmissionComponent, canActivate: [AuthGuard] },
  // { path: 'supplier-list', component: SupplierListComponent, canActivate: [AuthGuard]},
  // { path: 'supplier-profile/:name', component: SupplierProfileComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
