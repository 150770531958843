import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FileSizePipe } from './pipe/file-size.pipe';

import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from "primeng/menu";
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { FooterComponent } from './footer/footer.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { LoginComponent } from './login/login.component';
import { NavComponent } from './nav/nav.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { metaReducers } from './store/meta.reducer';
import { userProfileReducer } from './store/user.reducer';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from 'src/environments/environment';
import { AuthExpiredComponent } from './auth/auth-expired/auth-expired.component';
import { AppInitializerService } from './core/services/app-initializer.service';
import { NavigationEnd, Router } from '@angular/router';


const firebaseConfig = [
  provideFirebaseApp(() => initializeApp(environment.firebase)), // TODO: provide envrionment for firebase
  provideFirestore(() => getFirestore()),
  AngularFireModule.initializeApp(environment.firebase),
  AngularFirestoreModule.enablePersistence(),
  AngularFireStorageModule
];

const primeModules = [
  ConfirmDialogModule,
  ToastModule,
  SidebarModule,
  MenuModule,
  ProgressSpinnerModule,
  ToolbarModule,
];

const standaloneComponents = [
  AuthExpiredComponent,
];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    FooterComponent,
    FileSizePipe,
    SpinnerComponent,
    AuthCallbackComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    TooltipModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,

    StoreModule.forRoot({
      user: userProfileReducer,
    }, { metaReducers }),
    EffectsModule.forRoot([]),
    // DialogModule,
    // ButtonModule,

    // OverlayPanelModule,

    // TabMenuModule,
    // DropdownModule,
    // DividerModule,
    // ProgressBarModule,

    // PanelModule,
    // ChartModule,
    // BadgeModule,
    // CheckboxModule,
    // RadioButtonModule,
    // MultiSelectModule,
    // MessagesModule,
    ...primeModules,
    ...firebaseConfig,
    ...standaloneComponents,

    environment.production
      ? []
      : AkitaNgDevtools.forRoot({
        maxAge: 25,
      }),

  ],
  providers: [
    DialogService,
    ConfirmationService,
    DatePipe,
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.initializeApp(),
      deps: [AppInitializerService], // Inject dependencies
      multi: true, // Allow multiple APP_INITIALIZER providers
    },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
