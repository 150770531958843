import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { persistState } from '@datorama/akita';
import { environment } from './environments/environment';
import { formPersistStorage } from './app/modules/forms/state/form.store';

if (environment.production) {
  enableProdMode();
}

const storage = persistState({ storage: localStorage });
const providers = [{ provide: 'persistStorage', useValue: storage }];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
