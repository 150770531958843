import { AuthService } from 'src/app/state';
import { AuthQuery } from '../state/auth/auth.query';
// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authQuery: AuthQuery,
    private authService: AuthService,
    private router: Router) { }

  canActivate(): boolean {
    if (this.authQuery.getIdToken()
      && !this.authService.isTokenExpired()) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}