import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from "primeng/api";
import { OAuthService } from '../auth/oAuth.service';
import { DashboardService } from '../service/dashboard.service';
import { UserProfile } from './../model/user-profile.model';
import { Router } from '@angular/router';
import { AccountQuery, AccountService } from '../modules/account/state';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() profile!: UserProfile;
  @Input() logo!: string;
  items: MenuItem[] = [];
  sidebarVisible: boolean = false;

  startStaticMenu: MenuItem[] = [];
  companiesMenu: MenuItem[] = [];
  endStaticMenu: MenuItem[] = [];

  private destroy$ = new Subject<void>();
  userInfo$: Observable<any> = this.accountQuery.profile$;

  constructor(
    private oAuthService: OAuthService,
    private dashboardService: DashboardService,
    private router: Router,
    private accountService: AccountService,
    private accountQuery: AccountQuery,
  ) {
    this.dashboardService.sidebarVisibility$.subscribe(visible => {
      this.sidebarVisible = visible;
    });
  }

  ngOnInit() {
    this.accountQuery.profile$.pipe(
      filter(Boolean),
      takeUntil(this.destroy$),
    ).subscribe((profile) => {
      this.startStaticMenu = [
        {
          label: "Manage My Profile", command: () => {
            this.openProfilePage();
          }
        },
        {
          label: "User Management", command: () => {
            this.openManageUsersPage();
          }
        },
        // {
        //   label: "Settings", command: () => {
        //     // this.openSettingsPage();
        //   }
        // },
      ];

      this.endStaticMenu = [
        {
          label: "Logout", command: () => {
            this.handleLogout();
          }
        }
      ];

      this.companiesMenu = [
        ...profile.organizations.map((org: any, i: number) => ({
          label: org.companyName,
          id: org.orgId,
          class: i === 0 ? 'first-class' : i === profile.organizations.length - 1 ? 'last-class' : '',
          command: () => {
            this.accountService.switchUserCompany(org);
            if (this.accountService.isGPSNRSecretariat()) {
              this.router.navigate(['/dashboard/secretariat']);
            } else {
              this.router.navigate(['/dashboard/my-submissions/']);
            }
          }
        }))
      ];

      this.items = [
        ...this.startStaticMenu,
        ...this.companiesMenu,
        ...this.endStaticMenu
      ];
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleLogout() {
    this.oAuthService.logout();
  }

  openProfilePage() {
    this.router.navigateByUrl('/account/user-profile/view');
  }

  openManageUsersPage() {
    this.router.navigateByUrl('/account/user-management');
  }

  openSettingsPage() {
    this.router.navigateByUrl('/account/user-settings');
  }

  isGPSNRMember(){
    return this.accountService.isGPSNRMember();
  }

  isGPSNRSecretariat(){
    return this.accountService.isGPSNRSecretariat();
  }

  toggleSideNav() {
    this.dashboardService.toggleSidebar();

    this.dashboardService.sidebarVisibility$.subscribe(visible => {
      this.sidebarVisible = visible;
    });
  }

  handleSidebarClick(event: Event) {
    event.stopPropagation();
  }

  openNewWindow() {
    window.open('https://agridence.github.io/public-docs/GPSNR%20RR%20FAQ/faq/', '_blank');
  }
}
